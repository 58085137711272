<template>
    <v-row dense>
        <v-col cols="11" sm="10" offset-sm="1">
            <v-row class="d-flex justify-space-around mb-5 pt-5" cols="2">
                <!-- <v-btn color="primary" dark @click="reporteMiCaja" :loading="loadingReport2">
                        IMPRIMIR MI REPORTE
                        <v-icon small right>mdi-cloud-print</v-icon>
                    </v-btn> -->
                <v-btn color="primary" dark @click="reporteCaja" :loading="loadingReport">
                    IMPRIMIR REPORTE
                    <v-icon small right>mdi-cloud-print</v-icon>
                </v-btn>
            </v-row>
        </v-col>
        <v-col cols="12">
            <v-divider></v-divider>
        </v-col>
        <v-col cols="12" class="text-center pa-2">
            <!-- {{arrayMontos}} -->
            <v-row dense align="center">
                <v-col v-for="(item, key) in arrayMontos" :key="key" cols="4" style="border: 1px solid #f5f5f5;">
                    <v-row dense>
                        <v-col cols="12" class="grey white--text">{{ item.text }}</v-col>
                        <v-col cols="12" :class="`${item.color}--text`">s/. {{ item.total }}</v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-col>

        <v-col cols="12" class="my-4">
            <v-divider></v-divider>
        </v-col>
    </v-row>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import DetalleItem from '@/components/caja/DetalleItem'

export default {
    components: {
        DetalleItem
    },
    data() {
        return {
            loadingReport: false,
            loadingReport2: false
        }
    },
    props: ['items', 'itemsColaborador'],
    computed: {
        ...mapGetters('utilities', ['listTipoBancoDestinoOwn']),
        arrayMontos() {
            //console.log(' this.items.totaltransferencia', Number(this.items.totaltransferencia))
            const banco = (
                Number(this.items.totaltransferencia) +
                Number(this.items.totaltarjeta) +
                Number(this.items.totaldeposito) +
                Number(this.items.totalPagoLinkSoles) +
                Number(this.items.totalIzySoles) +
                Number(this.items.totalYapeSoles)
            ).toFixed(2);
            console.log('banco', banco)
            console.log('this.items', this.items)
            return [
                { text: 'Resumen Caja', total: this.items.resumen, color: 'black' },
                { text: 'Ventas', total: this.items.cajaTotal, color: 'black' },
                { text: 'Gastos', total: this.items.totalgastos, color: 'red' },
                {
                    text: 'Efectivo',
                    total: this.items.totalefectivo,
                    color: 'black'
                },
                {
                    text: 'Banco',
                    total: banco.toString(),
                    color: 'black'
                }
            ]
        },
        ...mapState('users', ['user'])
    },
    methods: {
        async reporteCaja() {
            this.loadingReport = true
            const { idBussines } = this.user
            const banco = (
                Number(this.items.totaltransferencia) +
                Number(this.items.totaltarjeta) +
                Number(this.items.totaldeposito) +
                Number(this.items.totalPagoLinkSoles) +
                Number(this.items.totalIzySoles)+
                Number(this.items.totalYapeSoles)
            ).toFixed(2); let reformItem = []
            console.log(' this.items.gastos-----', this.items.gastos)
            this.items.gastos.forEach((element) => {
                const resultado = element.sum_total == element.importeAbonado ? element.sum_total : element.sum_total * 10
                const sumaTotal = resultado.toFixed(2)
                reformItem.push({ ...element, sum_total: sumaTotal })
            })
            console.log('reformItem---', reformItem)
            let tipoReporte = idBussines === 2 ? 1 : idBussines === 4 ? 20 : 8
            const body = {
                ...this.items,
                gastos: reformItem,
                totaldeposito: banco,
                tipoReporte,
                listTipoBancoDestinoOwn: this.listTipoBancoDestinoOwn
            }
            console.log('body', body)
            await this.$store.dispatch('printer/detalleCajaCarbone', body)

            this.loadingReport = false
        },
        async reporteMiCaja() {
            this.loadingReport2 = true
            const { idBussines } = this.user
            let tipoReporte = idBussines === 2 || idBussines === 4 ? 1 : 8
            //console.log('...this.items', this.items)
            const body = {
                ...this.itemsColaborador,
                tipoReporte
            }
            console.log('body', body)
            await this.$store.dispatch('printer/detalleCajaCarbone', body)

            this.loadingReport2 = false
        }
    }
}
</script>
